import EducationItem from './EducationItem';

export default function EducationSection({educations}) {
  return (
    <div id="educations">
      {educations?.map((data, index) => {
        return <EducationItem key={`education-${index}`} {...data} />;
      })}
    </div>
  );
}
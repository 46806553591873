export default function FlagItem({name, icon, value, text}) {
  // Usage example
  // <FlagItem name="French" flag="france.jpg" value="100" text="mother tongue"/>

  const _getImage    = () => "/images/flags/" + icon;
  const _getImageAlt = () => `${name} flag`;

  return (
    <div className="row align-items-center">
      <img src={_getImage()} alt={_getImageAlt()}></img>
      <span>{name}</span>
      <div className="progress flex-fill">
        <div className="progress-bar progress-bar-striped" style={{width: `${value}%`}} role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax="100">{text}</div>
      </div>
    </div>
  );
}
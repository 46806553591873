export default function ContactCard({title, links, description}) {
  return (
    <div className="contact-card">
      <h1 className="text-center display-4">{title}</h1>
      
      {description?.map((data, idx) => {
        return (<p key={`contact-description-${idx}`}>{data}</p>);
      })}

      <div className="d-flex justify-content-center">
        <div>
          {links?.map((data, idx) => {
            return (data.active !== false && data.contact === true &&
              <a key={`contact-link-${idx}`} href={data.url} target="_blank" rel="noopener noreferrer">
                <i className={data.icon}></i>{data.text}
              </a>);
          })}
        </div>
      </div>
    </div>
  );
}
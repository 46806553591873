export default function Banner({title, dataActive, subtitle}) {
  return (
    <div className="container">
      <div className="row mx-0">
        <div className="col-sm-4 text-center text-sm-left">
          {dataActive &&
            <img className="main-picture img-fluid rounded-circle" src="/images/res/owner-720.jpg" alt="Software Freelancer in Zürich" ></img>}
        </div>
        <div className="col-sm-8 text-center text-sm-left align-self-center">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
      </div>
    </div>
  );
}
export default function EducationItem({start, end, title, subtitle, award, description}) {
  // Usage example
  // <EducationItem start="2007" 
  //                end="2008" 
  //                title="Cranfield university - cranfield (UK)" 
  //                subtitle="MSc Gas Turbine Technology"
  //                award="Thermal Power Prize"
  //                description="Gas Turbine theory and performances (Gas turbine simulation at design and off-design conditions, deterioration diagnostics), Turbomachinery design (Compressor, Turbine, cooling ...), Combustion chamber design, Control System design. Project Management."
  //                />

  return (
    <div className="mt-3">
      <h4>{title}</h4>
      <h5>
        <span className="shift">{start + (end ? "-" + end : "")}</span>{subtitle}
        {award && <p className="award"><span className="shift"/><i className="fas fa-trophy"></i> {award}</p> }
      </h5>
      <p className="group-description">{description}</p>
    </div>
  );
}
export default function Footer({title, links}) {
  return (
    <div>
      {/* Component of same height than footer since it is placed fixed in front. */}
      <div className="footer-height"></div>
      {/* Footer. */}
      <footer className="fixed-bottom" id="footer">
        <div className="container">
          <div className="row align-items-center justify-content-between footer-height">
            <div className="col text-left d-none d-lg-block">
              <h4 style={{ display: "inline" }}>{title}</h4>
            </div>
            <div className="col text-lg-center">
              {links?.map((data, idx) => {
                return data.active !== false && (
                  <a key={`footer-link-${idx}`} href={data.url} target="_blank" rel="noopener noreferrer">
                    <i className={data.icon}></i>
                  </a>);
              })}
            </div>
            <div className="col text-right">
              <p style={{ display: "inline" }}>&copy; Copyright {new Date().getFullYear()}.<span className="d-none d-md-inline"> All rights reserved.</span></p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default function ExperienceTask({task, description, technology, titles}) {
  return (
    <div className="group-description mb-3">
      {task && <span className="role-title">{task}</span>}
      {description.map((line,idx) => {
        return (<p key={`task-dsc-${idx}`}>{line}</p>);
      })}
      {technology && 
        (<p className="mt-1">
          <span className="technology">{titles?.technology}: </span>
          {technology.join(", ")}
        </p>)}
    </div>
  );
}
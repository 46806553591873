export default function ResumeSkillItem({name, value}) {
  // Usage example
  // <ResumeSkillItem name="C#" value="100"/>

  return (
    <div className="row align-items-center">
      <span>{name}</span>
      <div className="progress flex-fill">
        <div className="progress-bar progress-bar-striped" style={{width: `${value}%`}} role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  );
}
import ExperienceRole from './ExperienceRole';

export default function ExperienceSection({experiences, titles}) {
  return (
    <div className="d-flex flex-column" id="experiences">
      {experiences?.map((data, index) => {
        return <ExperienceRole key={`experience-${index}`} {...data} titles={titles}/>;
      })}
    </div>
  );
}
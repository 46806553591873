import { useEffect, useState } from 'react';
import ContactCard from './shared/ContactCard';
import * as helpers from './shared/helpers';

export default function ProjectsView({language, links}) {
  const [config, setConfig] = useState(undefined);

  // Fetch view data
  const server = (process.env.NODE_ENV !== 'production' ? '' : '');
  useEffect(() => {
    fetch(server + `/data/${language}/projects.json`)
    .then(results => results.json())
    .then(data => {
      setConfig(data)
    })
  }, [language, server]);

  const _getTech = (data) => {
    if (!data?.tech) return null;
    try { return data.tech?.join(", ")}
    catch { return null; }
  }

  return (
    <div id="projects">
      <section id="intro">
        <div className="container">
          <ContactCard title={config?.successes} description={config?.description} links={links} />
        </div>
      </section>

      <div id="references">
        <div className="container">
          <h1 className="text-center display-4">{config?.projects}</h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            { // [ { name, company, role, tech[], date, text[], img, alt, url* }... ]
              config?.references?.map((data, idx) => {
                return (
                <section key={`projects-${idx}`} className="col">
                  <div className="card mb-5 border-0">
                    <div className="card-body p-0">
                      <img className="card-img-top rounded pb-2" src={"/images/res/" + data.img} alt={data.alt}></img>
                      <h4 className="card-title text-center">{data.name}</h4>
                      <h5 className="card-subtitle text-center">{data.company} ({data.date})</h5>
                      <h6 className="text-left">{data.role}</h6>
                      <div className="toggled-text">
                        <p className="card-text small">{_getTech(data)}</p>
                        <h6>{config?.situation}</h6>
                        {data.scope?.map((text, idx2) => {
                          return (<p key={`project-${idx}-situation-${idx2}`} className="card-text small">{text}</p>);
                        })}
                        <h6>{config?.actions}</h6>
                        <ul>
                          {data.actions?.map((text, idx2) => {
                            return (<li key={`project-${idx}-actions-${idx2}`} className="card-text small">{text}</li>);
                          })}
                        </ul>
                        <h6>{config?.results}</h6>
                        {data.results?.map((text, idx2) => {
                          return (<p key={`project-${idx}-results-${idx2}`} className="card-text small">{text}</p>);
                        })}
                      </div>
                      <button className="btn btn-light btn-info p-0 mt-2 readmore-btn stretched-link" onClick={helpers.toggleReadMore}>
                        <span className="readmore-txt">{config?.moreInfo}</span><span className="readless-txt">{config?.lessInfo}</span>
                      </button>
                    </div>
                  </div>
                </section>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
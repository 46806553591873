import ExperienceTask from './ExperienceTask';

export default function ExperienceRole({title, company, start, end, tasks, titles}) {
  // Usage example
  // <ExperienceRole title="Senior Software Engineer" 
  //                 company="GE Digital - Baden (CH)"
  //                 start="2016/06" 
  //                 end="Ongoing" 
  //                 tasks=[]
  //                />

  return (
    <section className="role mt-1">
      <h4>{title}</h4>
      <h5><span className="shift">{start + (end ? "-" + end : "")}</span>{company}</h5>
      {tasks.map((data,idx) => {
        return (<ExperienceTask key={`experience-task-${idx}`} {...data} titles={titles}/>);
      })}
    </section>
  );
}
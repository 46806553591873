import FlagItem from './FlagItem';

export default function FlagsSection({languages}) {
  return (
    <div className="col language-flags">
      {languages?.map((data, index) => {
        return <FlagItem key={`flag-${index}`} {...data}/>;
      })}
    </div>
  );
}
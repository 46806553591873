import { useEffect, useState } from 'react';
import ContactCard from './shared/ContactCard';
import * as helpers from './shared/helpers';

export default function HomeView({language, links}) {
  const [config, setConfig] = useState(undefined);

  // Fetch view data
  const server = (process.env.NODE_ENV !== 'production' ? '' : '');
  useEffect(() => {
    fetch(server + `/data/${language}/home.json`)
    .then(results => results.json())
    .then(data => setConfig(data));
  }, [language, server]);

  return (
    <div id="home">

      {/* Catch phrase */}
      <section id="catch">
        <div className="container">
          <div className="img-text">
            <h4>{config?.subtitle}</h4>
            <h1>{config?.title}</h1>
          </div>
        </div>
      </section>
      
      {/* Offering */}
      <div id="goal">
        <div className="container">
          <h1 className="text-center display-4">{config?.offers_title}</h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            { // [ { name, text, title, img, alt }... ]
              config?.offers?.map((data, idx) => {
                return (
                <section key={`offer-${idx}`} className="col">
                  <div className="card mb-5 border-0">
                    <div className="card-body p-0">
                      <img className="card-img-top rounded pb-2" src={"/images/res/" + data.img} alt={data.alt}></img>
                      <h4 className="card-subtitle text-center">{data.name}</h4>
                      <h5 className="card-title text-center">{data.title}</h5>
                      <div className="toggled-text">
                        {data.text.map((text, idx2) => {
                          return (<p key={`offer-${idx}-profile-${idx2}`} className="card-text small">{text}</p>);
                        })}
                      </div>
                      <button className="btn btn-light btn-info p-0 mt-2 readmore-btn stretched-link" onClick={helpers.toggleReadMore}>
                        <span className="readmore-txt">More info</span><span className="readless-txt">Less info</span>
                      </button>
                    </div>
                  </div>
                </section>
                );
              })
            }
          </div>
        </div>
      </div>

      <section id="contact">
        <div className="container">
          <ContactCard title={config?.contact_title} description={config?.contact} links={links} />
        </div>
      </section>
    </div>
  );
}
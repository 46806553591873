import { useEffect, useState } from 'react';

export default function AboutView({language, links}) {
  const [config, setConfig] = useState(undefined);

  // Fetch view data
  const server = (process.env.NODE_ENV !== 'production' ? '' : '');
  useEffect(() => {
    fetch(server + `/data/${language}/about.json`)
    .then(results => results.json())
    .then(data => setConfig(data));
  }, [language, server]);

  return (
    <div className="container">
      <section id="about">
        <div className="display-3 text-center mb-3">{config?.subtitle}</div>
        <div className="d-flex flex-column flex-md-row mb-3">
          <div className="align-self-center align-self-md-start justify-self-center">
            <img className="img-fluid rounded" src="/images/res/owner-1140.jpg" alt="Owner in black &amp; white"></img>
          </div>
          <div className="d-flex flex-column align-self-center">
            <h1>{config?.title}</h1>

            <blockquote className="blockquote">
              {config?.quote}
              <footer className="blockquote-footer">{config?.name}</footer>
            </blockquote>
          </div>
        </div>
        <div className="d-flex flex-column">
          {config?.description?.map((data, idx, arr) => {
            return (<p key={`home-desc-${idx}`} className={arr.length - 1 === idx ? "flex-fill" : undefined} >{data}</p>);
          })}

          <div className="align-self-end">
            {links?.map((data, idx) => {
              return data.active !== false && (
                <a key={`about-link-${idx}`} href={data.url} target="_blank" rel="noopener noreferrer">
                  <i className={data.icon}></i>
                </a>);
            })}
            <a href={`/${language}/resume`} target="_blank" rel="noopener noreferrer">
              <i className="fas fa-file-alt"></i> {config?.resume}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
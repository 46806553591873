export default function Navbar({language, brand, views, click, reload, active}) {
  const flags = {
    "en" : { "image" : "/images/flags/uk.png",      "alt" : "English flag", "label" : "English" },
    "de" : { "image" : "/images/flags/germany.png", "alt" : "German flag",  "label" : "Deutsch" },
  };

  return (
    <nav className="navbar navbar-dark navbar-expand-sm sticky-top" id="main-nav">
      <div className="container">
        <a href={`/${language}/home`} className="navbar-brand">{brand}</a>
        
        <button className="navbar-toggler navbar-toggler-right" type="button"
          data-toggle="collapse" data-target="#togglerNav"
          aria-controls="togglerNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="togglerNav">
          <div className="navbar-nav ml-auto">
            {/* Language */}
            <ul className="navbar-nav">
              <li className="dropdown my-auto mx-2">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                  <img src={`${flags[language].image}`} alt={`${flags[language].alt}`}></img>
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li><a href={`/en/${active}`}><img src={`${flags['en'].image}`} alt={`${flags['en'].alt}`}></img>{`${flags['en'].label}`}</a></li>
                  <li><a href={`/de/${active}`}><img src={`${flags['de'].image}`} alt={`${flags['de'].alt}`}></img>{`${flags['de'].label}`}</a></li>
                </ul>
              </li>
            </ul>
            { // Add all menu entries
              views.map((view, idx) => {
                if (view.show === false) return null;
                if (reload) {
                  return (<a key={`navitem-${idx}`} className={ "nav-item nav-link" + (active === view.url ? " active" : "")} href={`/${language}/${view.url}`}>{view.name}</a>);
                } else {
                  return (<a key={`navitem-${idx}`} className={ "nav-item nav-link" + (active === view.url ? " active" : "")} data-toggle="collapse" data-target=".navbar-collapse.show" onClick={() => click(view.url)}>{view.name}</a>);
                }
              })
            }
          </div>
        </div>
      </div>
    </nav>
  );
}
import ContactItem from './ContactItem';

export default function ContactSection({contacts}) {
  return (
    <div className="d-flex flex-column mb-3">
      {contacts?.map((data, index) => {
        if (data.resume === false) return null;
        return <ContactItem key={`contact-${index}`} {...data} />;
      })}
    </div>
  );
}
import SkillItem from './SkillItem';

export default function SkillSection({skills}) {
  return (
    <div className="col skills mb-3">
      {skills?.map((data, index) => {
        return <SkillItem key={`skill-${index}`} {...data} />;
      })}
    </div>
  );
}
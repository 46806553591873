import './sass/App.scss';
import { createElement, useEffect, useState } from 'react';

import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Footer from './components/Footer';
import HomeView from './components/views/HomeView';
import AboutView from './components/views/AboutView';
import ProjectsView from './components/views/ProjectsView';
//import NewsView from './components/views/NewsView';
//import BlogView from './components/views/BlogView';
import ResumeView from './components/views/ResumeView';

// Views
var views = [
  { name: "Home",         url: "home",        comp: HomeView       },
  { name: "Projects",     url: "projects",    comp: ProjectsView   },
//{ name: "News",         url: "news",        comp: NewsView       },
//{ name: "Blog",         url: "blog",        comp: BlogView       },
  { name: "About",        url: "about",       comp: AboutView,
    picture: false },
  { name: "Resume",       url: "resume",      comp: ResumeView,   show: false, 
    navbar: false, footer: false },
  { name: "Admin",        url: "admin",       comp: "AdminView",  show: false  }
];

export default function App() {
  const [config,   setConfig] = useState(undefined);
  
  // Functions
  const _getViewConfig = (views, view) => {
    for (var i = 0; i < views.length; i++) {
      if (views[i].url !== view) continue;
      return views[i];
    }
  }
  const changeView = (view) => {
    var viewData = _getViewConfig(views, view);
    if (viewData === null) return;
    setView(view);
  }
  const _getViewComponent = (viewData, links, lang) => {
    return viewData ? createElement(viewData.comp, { links : links, language : lang }) : "Unknown view";
  }
  
  // Initialise view and language
  var a = document.createElement('a');
  a.href = window.location.href;
  var paths = a.pathname === '/' ? [] : a.pathname.split('/');
  const [view,     setView] = useState(paths.length > 2 && _getViewConfig(views, paths[2]) ? paths[2] : 'home');
  const [language, setLanguage] = useState(paths.length > 1 ? paths[1] : 'en');
  if (language !== 'en' && language !== 'de') setLanguage('en'); // Validate language


  // Fetch view data
  const server = (process.env.NODE_ENV !== 'production' ? '' : '');
  useEffect(() => {
    fetch(server + `/data/${language}/config.json`)
    .then(results => results.json())
    .then(data => setConfig(data));
  }, [language, server]);

    let viewData = _getViewConfig(views, view);

  return (
    <div>
      <header>
        <Banner dataActive={viewData.picture !== false}
                title={config?.brand?.[view] ?? config?.brand?.default}
                subtitle={config?.pitch?.[view] ?? config?.pitch?.default} />
      </header>
      {viewData.navbar !== false && <Navbar brand={config?.brand?.default} language={language} reload={config?.reload} views={views} active={view} click={changeView} />}
      <main>
        {_getViewComponent(viewData, config?.links, language)}
      </main>
      {viewData.footer !== false && <Footer dataActive="true" title={config?.contact} links={config?.links} />}
    </div>
  );
}

import { useEffect, useState } from 'react';
import FlagSection from './resume/FlagSection';
import SkillSection from './resume/SkillSection';
import ContactSection from './resume/ContactSection';
import EducationSection from './resume/EducationSection';
import ExperienceSection from './resume/ExperienceSection';

export default function ResumeView({language, links}) {
  const [config, setConfig] = useState(undefined);

  // Fetch view data
  const server = (process.env.NODE_ENV !== 'production' ? '' : '');
  useEffect(() => {
    fetch(server + `/data/${language}/resume.json`)
    .then(results => results.json())
    .then(data => {
      setConfig(data)
    })
  }, [language, server]);

  return (
    <div id="resume">
      {/* ----- left panel ----- */}
      <div className="left">
        <section className="mb-3">
          <h3>{config?.titles?.profile}</h3>
          {config?.profile?.map((data, idx) => {
            return (<p key={`profile-${idx}`}>{data}</p>);
          })}
        </section>
        <section className="mb-3">
          <h3>{config?.titles?.contact}</h3>
          <ContactSection key={`contact-section`} contacts={config?.contacts} />
          <ContactSection key={`link-section`}    contacts={links} />
        </section>
        <section className="mb-3">
          <h3>{config?.titles?.languages}</h3>
          <FlagSection languages={config?.languages} />
        </section>
        <section className="mb-3" id="skills">
          <h3>{config?.titles?.skills}</h3>
          {config?.skills?.map((data, idx) => {
            return (<SkillSection key={`skill-section-${idx}`} skills={data} />);
          })}
        </section>
        <section className="mb-3">
          <h3>{config?.titles?.personality}</h3>
          {config?.personality?.map((data, idx) => {
            return (<p key={`perso-${idx}`}>{data}</p>);
          })}
        </section>
      </div>
      
      {/* ----- right panel ----- */}
      <div className="right">
        <section className="mb-3">
          <h3>{config?.titles?.experiences}</h3>
          <ExperienceSection experiences={config?.experiences} titles={config?.titles} />
        </section>

        <section className="pb-3">
          <h3>{config?.titles?.education}</h3>
          <EducationSection educations={config?.educations} />
          </section>
      </div>
    </div>
  );
}